const RelationshipBangla = (value) => {
    let banglaName;
    if (value === 'swami') {
        banglaName = 'স্বামী'
    }
    else if (value === 'wife') {
        banglaName = 'স্ত্রী'
    }
    else if (value === 'son') {
        banglaName = 'পুত্র'
    }
    else if (value === 'daughter') {
        banglaName = 'কন্যা'
    }
    else if (value === 'mother') {
        banglaName = 'মাতা'
    }
    else if (value === 'grandfather') {
        banglaName = 'দাদা'
    }
    else if (value === 'father') {
        banglaName = 'বাবা'
    }
    else if (value === 'grandmother') {
        banglaName = 'দাদী'
    }
    else if (value === 'grandmother') {
        banglaName = 'নানি'
    }
    else if (value === 'dead-son') {
        banglaName = 'মৃত-পুত্র'
    }
    else if (value === 'dead-daughter') {
        banglaName = 'মৃত-কন্যা'
    }
    else if (value === 'dead-grandfather') {
        banglaName = 'মৃত-দাদা'
    }
    else if (value === 'dead-grandfather') {
        banglaName = 'মৃত-নানি'
    }
    else if (value === 'brother') {
        banglaName = 'ভাই'
    }
    else if (value === 'sister') {
        banglaName = 'বোন'
    }
    else if (value === 'granddaughter') {
        banglaName = 'নাতনির'
    }
    else if (value === 'grandson') {
        banglaName = 'নাতি'
    }
    else if (value === 'self') {
        banglaName = 'নিজ'
    }
    return banglaName;  
};
export default RelationshipBangla;


